"use strict";
import { scrollbarStyle } from "components/SearchModal/CurrencyList/index.css";
import { SearchInput } from "components/SearchModal/styled";
import { CountryListRow } from "pages/Swap/Buy/CountryListRow";
import { ContentWrapper } from "pages/Swap/Buy/shared";
import { useCallback, useMemo, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { CloseIcon } from "theme/components";
import { AdaptiveWebModal, Flex, styled } from "ui/src";
import { Text } from "ui/src/components/text/Text";
import { useTranslation } from "uniswap/src/i18n";
import { INTERFACE_NAV_HEIGHT } from "uniswap/src/theme/heights";
import { bubbleToTop } from "utilities/src/primitives/array";
const ROW_ITEM_SIZE = 56;
export const HeaderContent = styled(Flex, {
  flexShrink: 1,
  $sm: { pt: "$none" },
  p: "$spacing20",
  gap: "$spacing12"
});
export function CountryListModal({
  isOpen,
  onDismiss,
  countryList,
  selectedCountry,
  onSelectCountry
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const filteredData = useMemo(() => {
    const sorted = bubbleToTop(countryList, (c) => c.countryCode === selectedCountry?.countryCode);
    if (searchQuery) {
      return sorted.filter((item) => item?.displayName.toLowerCase().startsWith(searchQuery.toLowerCase()));
    } else {
      return sorted;
    }
  }, [countryList, searchQuery, selectedCountry?.countryCode]);
  const fixedList = useRef();
  const handleInput = useCallback((event) => {
    const input = event.target.value;
    setSearchQuery(input);
    fixedList.current?.scrollTo(0);
  }, []);
  const closeModal = useCallback(() => {
    setSearchQuery("");
    onDismiss();
  }, [onDismiss]);
  return <AdaptiveWebModal
    p={0}
    isOpen={isOpen}
    flex={1}
    onClose={closeModal}
    maxHeight={700}
    $sm={{ height: `calc(100dvh - ${INTERFACE_NAV_HEIGHT}px)` }}
  ><ContentWrapper>
    <HeaderContent>
      <Flex width="100%" row justifyContent="space-between">
        <Text variant="body2">{t("common.selectRegion.label")}</Text>
        <CloseIcon data-testid="CountryListModal-close" onClick={closeModal} />
      </Flex>
      <SearchInput
        type="text"
        id="for-country-search-input"
        data-testid="for-country-search-input"
        placeholder={t`swap.buy.countryModal.placeholder`}
        autoComplete="off"
        value={searchQuery}
        onChange={handleInput}
      />
    </HeaderContent>
    <Flex grow><AutoSizer disableWidth>{({ height }) => <div data-testid="country-list-wrapper"><FixedSizeList
      className={scrollbarStyle}
      height={height}
      ref={fixedList}
      width="100%"
      itemData={filteredData}
      itemCount={filteredData.length}
      itemSize={ROW_ITEM_SIZE}
      itemKey={(index, data) => data[index]?.countryCode}
    >{({ style, data, index }) => <CountryListRow
      style={style}
      country={data[index]}
      selectedCountry={selectedCountry}
      onClick={() => {
        onSelectCountry(data[index]);
        closeModal();
      }}
    />}</FixedSizeList></div>}</AutoSizer></Flex>
  </ContentWrapper></AdaptiveWebModal>;
}
