"use strict";
import { DepositInputForm } from "components/Liquidity/DepositInputForm";
import { useCreatePositionContext, useDepositContext } from "pages/Pool/Positions/create/CreatePositionContext";
import { CreatePositionModal } from "pages/Pool/Positions/create/CreatePositionModal";
import { Container } from "pages/Pool/Positions/create/shared";
import { useCallback, useState } from "react";
import { Button, Flex, Text } from "ui/src";
import { Trans } from "uniswap/src/i18n";
export const DepositStep = ({ ...rest }) => {
  const {
    derivedPositionInfo: { sortedTokens }
  } = useCreatePositionContext();
  const {
    setDepositState,
    derivedDepositInfo: { formattedAmounts, currencyAmounts, currencyAmountsUSDValue, currencyBalances }
  } = useDepositContext();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const handleUserInput = (field, newValue) => {
    setDepositState((prev) => ({
      ...prev,
      exactField: field,
      exactAmount: newValue
    }));
  };
  const handleOnSetMax = (field, amount) => {
    setDepositState((prev) => ({
      ...prev,
      exactField: field,
      exactAmount: amount
    }));
  };
  const handleReview = useCallback(() => {
    setIsReviewModalOpen(true);
  }, []);
  const [token0, token1] = sortedTokens ?? [void 0, void 0];
  if (!token0 || !token1) {
    return null;
  }
  return <>
    <Container {...rest}>
      <Flex gap={32}><Flex row alignItems="center"><Flex>
        <Text variant="subheading1"><Trans i18nKey="common.depositTokens" /></Text>
        <Text variant="body3" color="$neutral2"><Trans i18nKey="position.deposit.description" /></Text>
      </Flex></Flex></Flex>
      <DepositInputForm
        token0={token0}
        token1={token1}
        formattedAmounts={formattedAmounts}
        currencyAmounts={currencyAmounts}
        currencyAmountsUSDValue={currencyAmountsUSDValue}
        currencyBalances={currencyBalances}
        onUserInput={handleUserInput}
        onSetMax={handleOnSetMax}
      />
      <Button
        flex={1}
        py="$spacing16"
        px="$spacing20"
        onPress={handleReview}
        disabled={!currencyAmounts?.TOKEN0 || !currencyAmounts.TOKEN1}
      ><Text variant="buttonLabel1"><Trans i18nKey="swap.button.review" /></Text></Button>
    </Container>
    <CreatePositionModal isOpen={isReviewModalOpen} onClose={() => setIsReviewModalOpen(false)} />
  </>;
};
