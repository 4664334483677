import { datadogLogs } from "@datadog/browser-logs";
import { isTestEnv } from "utilities/src/environment/env";
import { NotImplementedError } from "utilities/src/errors";
import { v4 as uuidv4 } from "uuid";
const USER_ID_KEY = "datadog-user-id";
export function setupDatadog(envNameFunc) {
  if (isTestEnv()) {
    return;
  }
  if (!process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    console.error(`No datadog client token, disabling`);
    return;
  }
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    forwardErrorsToLogs: true
  });
  let userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    localStorage.setItem(USER_ID_KEY, userId = uuidv4());
  }
  datadogLogs.setUser({
    id: userId
  });
  datadogLogs.setUserProperty("env", envNameFunc());
  datadogLogs.setUserProperty("version", process.env.REACT_APP_GIT_COMMIT_HASH);
}
export function logToDatadog(message, {
  level,
  ...options
}) {
  if (isTestEnv()) {
    return;
  }
  datadogLogs.logger[level](message, options);
}
export function logWarningToDatadog(_message, _options) {
  throw new NotImplementedError("logWarningToDatadog");
}
export function logErrorToDatadog(error, context) {
  if (isTestEnv()) {
    return;
  }
  if (error instanceof Error) {
    datadogLogs.logger.error(error.message, {
      error: {
        kind: error.name,
        stack: error.stack
      },
      ...context
    });
  } else {
    datadogLogs.logger.error(error, {
      error: {
        stack: new Error().stack
      },
      ...context
    });
  }
}
export function attachUnhandledRejectionHandler() {
  throw new NotImplementedError("attachUnhandledRejectionHandler");
}
export async function setAttributesToDatadog(_attributes) {
  throw new NotImplementedError("setAttributes");
}
