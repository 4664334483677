"use strict";
import {
  PoolPosition,
  PositionStatus,
  ProtocolVersion,
  Pool as RestPool
} from "@uniswap/client-pools/dist/pools/v1/types_pb";
import { CurrencyAmount, Percent, Token } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { FeeAmount, Pool, Position } from "@uniswap/v3-sdk";
import { getPriceOrderingFromPositionForUI } from "components/PositionListItem";
import JSBI from "jsbi";
import { useMemo } from "react";
import { useAppSelector } from "state/hooks";
import { ProtocolItems } from "uniswap/src/data/tradingApi/__generated__";
import { useUSDCPrice } from "uniswap/src/features/transactions/swap/hooks/useUSDCPrice";
export function getProtocolVersionLabel(version) {
  switch (version) {
    case ProtocolVersion.V2:
      return "V2";
    case ProtocolVersion.V3:
      return "V3";
    case ProtocolVersion.V4:
      return "V4";
  }
  return void 0;
}
export function getProtocolItems(version) {
  switch (version) {
    case ProtocolVersion.V2:
      return ProtocolItems.V2;
    case ProtocolVersion.V3:
      return ProtocolItems.V3;
    case ProtocolVersion.V4:
      return ProtocolItems.V4;
  }
  return void 0;
}
export function getProtocolStatusLabel(status, t) {
  switch (status) {
    case PositionStatus.IN_RANGE:
      return t("common.withinRange");
    case PositionStatus.OUT_OF_RANGE:
      return t("common.outOfRange");
    case PositionStatus.CLOSED:
      return t("common.closed");
  }
  return void 0;
}
export function parseProtocolVersion(version) {
  switch (version?.toLowerCase()) {
    case "v2":
      return ProtocolVersion.V2;
    case "v3":
      return ProtocolVersion.V3;
    case "v4":
      return ProtocolVersion.V4;
    default:
      return void 0;
  }
}
export function parseV3FeeTier(feeTier) {
  const parsedFee = parseInt(feeTier || "");
  return parsedFee in FeeAmount ? parsedFee : void 0;
}
export function getPoolFromRest({
  pool,
  token0,
  token1
}) {
  if (!pool || !token0 || !token1) {
    return void 0;
  }
  if (pool instanceof RestPool) {
    return new Pool(token0, token1, pool.fee, pool.sqrtPriceX96, pool.liquidity, pool.tick);
  }
  if (pool instanceof PoolPosition) {
    const feeTier = parseV3FeeTier(pool.feeTier);
    if (feeTier) {
      return new Pool(token0, token1, feeTier, pool.currentPrice, pool.liquidity, parseInt(pool.currentTick));
    }
  }
  return void 0;
}
function parseRestToken(token) {
  if (!token) {
    return void 0;
  }
  return new Token(token.chainId, token.address, token.decimals, token.symbol);
}
export function getPairFromRest({
  pair,
  token0,
  token1
}) {
  if (!pair) {
    return void 0;
  }
  return new Pair(
    CurrencyAmount.fromRawAmount(token0, pair.reserve0),
    CurrencyAmount.fromRawAmount(token1, pair.reserve1)
  );
}
export function parseRestPosition(position) {
  if (position?.position.case === "v2Pair") {
    const v2PairPosition = position.position.value;
    const token0 = parseRestToken(v2PairPosition.token0);
    const token1 = parseRestToken(v2PairPosition.token1);
    const liquidityToken = parseRestToken(v2PairPosition.liquidityToken);
    if (!token0 || !token1 || !liquidityToken) {
      return void 0;
    }
    const pair = getPairFromRest({ pair: position.position.value, token0, token1 });
    return {
      status: position.status,
      version: ProtocolVersion.V2,
      pair,
      liquidityToken,
      currency0Amount: CurrencyAmount.fromRawAmount(token0, v2PairPosition.liquidity0),
      currency1Amount: CurrencyAmount.fromRawAmount(token1, v2PairPosition.liquidity1),
      totalSupply: CurrencyAmount.fromRawAmount(liquidityToken, v2PairPosition.totalSupply),
      liquidityAmount: CurrencyAmount.fromRawAmount(liquidityToken, v2PairPosition.liquidity),
      v4hook: void 0,
      feeTier: void 0
    };
  } else if (position?.position.case === "v3Position") {
    const v3Position = position.position.value;
    const token0 = parseRestToken(v3Position.token0);
    const token1 = parseRestToken(v3Position.token1);
    if (!token0 || !token1) {
      return void 0;
    }
    const pool = getPoolFromRest({ pool: position.position.value, token0, token1 });
    const sdkPosition = pool ? new Position({
      pool,
      liquidity: v3Position.liquidity,
      tickLower: Number(v3Position.tickLower),
      tickUpper: Number(v3Position.tickUpper)
    }) : void 0;
    return {
      status: position.status,
      feeTier: parseV3FeeTier(v3Position.feeTier),
      version: ProtocolVersion.V3,
      pool,
      position: sdkPosition,
      tickLower: v3Position.tickLower,
      tickUpper: v3Position.tickUpper,
      tickSpacing: Number(v3Position.tickSpacing),
      liquidity: v3Position.liquidity,
      tokenId: v3Position.tokenId,
      token0UncollectedFees: v3Position.token0UncollectedFees,
      token1UncollectedFees: v3Position.token1UncollectedFees,
      currency0Amount: CurrencyAmount.fromRawAmount(token0, v3Position.amount0),
      currency1Amount: CurrencyAmount.fromRawAmount(token1, v3Position.amount1),
      v4hook: void 0
    };
  } else if (position?.position.case === "v4Position") {
    const v4Position = position.position.value.poolPosition;
    const token0 = parseRestToken(v4Position?.token0);
    const token1 = parseRestToken(v4Position?.token1);
    if (!v4Position || !token0 || !token1) {
      return void 0;
    }
    return {
      status: position.status,
      feeTier: v4Position?.feeTier,
      version: ProtocolVersion.V4,
      position: void 0,
      // add support for this
      v4hook: position.position.value.hooks[0]?.address,
      tokenId: v4Position.tokenId,
      tickLower: v4Position?.tickLower,
      tickUpper: v4Position?.tickUpper,
      tickSpacing: Number(v4Position?.tickSpacing),
      currency0Amount: CurrencyAmount.fromRawAmount(token0, v4Position?.amount0 ?? 0),
      currency1Amount: CurrencyAmount.fromRawAmount(token1, v4Position?.amount1 ?? 0)
    };
  } else {
    return void 0;
  }
}
export function useModalLiquidityPositionInfo() {
  const modalState = useAppSelector((state) => state.application.openModal);
  return modalState?.initialState;
}
export function useGetPoolTokenPercentage(positionInfo) {
  const { totalSupply, liquidityAmount } = positionInfo ?? {};
  const poolTokenPercentage = useMemo(() => {
    return !!liquidityAmount && !!totalSupply && JSBI.greaterThanOrEqual(totalSupply.quotient, liquidityAmount.quotient) ? new Percent(liquidityAmount.quotient, totalSupply.quotient) : void 0;
  }, [liquidityAmount, totalSupply]);
  return poolTokenPercentage;
}
export function useV3PositionDerivedInfo(positionInfo) {
  const {
    token0UncollectedFees,
    token1UncollectedFees,
    currency0Amount,
    currency1Amount,
    liquidity,
    tickLower,
    tickUpper
  } = positionInfo ?? {};
  const { price: price0 } = useUSDCPrice(currency0Amount?.currency);
  const { price: price1 } = useUSDCPrice(currency1Amount?.currency);
  const { feeValue0, feeValue1 } = useMemo(() => {
    if (!currency0Amount || !currency1Amount) {
      return {};
    }
    return {
      feeValue0: token0UncollectedFees ? CurrencyAmount.fromRawAmount(currency0Amount.currency, token0UncollectedFees) : void 0,
      feeValue1: token1UncollectedFees ? CurrencyAmount.fromRawAmount(currency1Amount.currency, token1UncollectedFees) : void 0
    };
  }, [currency0Amount, currency1Amount, token0UncollectedFees, token1UncollectedFees]);
  const { fiatFeeValue0, fiatFeeValue1 } = useMemo(() => {
    if (!price0 || !price1 || !currency0Amount || !currency1Amount || !feeValue0 || !feeValue1) {
      return {};
    }
    const amount0 = price0.quote(feeValue0.wrapped);
    const amount1 = price1.quote(feeValue1.wrapped);
    return {
      fiatFeeValue0: amount0,
      fiatFeeValue1: amount1
    };
  }, [price0, price1, currency0Amount, currency1Amount, feeValue0, feeValue1]);
  const { fiatValue0, fiatValue1 } = useMemo(() => {
    if (!price0 || !price1 || !currency0Amount || !currency1Amount) {
      return {};
    }
    const amount0 = price0.quote(currency0Amount);
    const amount1 = price1.quote(currency1Amount);
    return {
      fiatValue0: amount0,
      fiatValue1: amount1
    };
  }, [price0, price1, currency0Amount, currency1Amount]);
  const priceOrdering = useMemo(() => {
    if (positionInfo?.version !== ProtocolVersion.V3 || !positionInfo.position || !liquidity || !tickLower || !tickUpper) {
      return {};
    }
    return getPriceOrderingFromPositionForUI(positionInfo.position);
  }, [liquidity, tickLower, tickUpper, positionInfo]);
  return useMemo(
    () => ({
      fiatFeeValue0,
      fiatFeeValue1,
      fiatValue0,
      fiatValue1,
      priceOrdering,
      feeValue0,
      feeValue1,
      token0CurrentPrice: positionInfo?.version === ProtocolVersion.V3 ? positionInfo.pool?.token0Price : void 0,
      token1CurrentPrice: positionInfo?.version === ProtocolVersion.V3 ? positionInfo.pool?.token0Price : void 0
    }),
    [fiatFeeValue0, fiatFeeValue1, fiatValue0, fiatValue1, priceOrdering, feeValue0, feeValue1, positionInfo]
  );
}
