"use strict";
import {
  calculateColumnPositionsInPlace,
  isStackedHistogramData,
  positionsBox
} from "components/Charts/VolumeChart/utils";
function cumulativeBuildUp(data) {
  let sum = 0;
  return Object.values(data.values).filter((value) => value !== void 0).map((value) => {
    const newValue = sum + value;
    sum = newValue;
    return newValue;
  });
}
export class CustomHistogramSeriesRenderer {
  _data = null;
  _options = null;
  _colors;
  _isMultichainExploreEnabled;
  _background;
  constructor(props) {
    this._colors = props.colors;
    this._isMultichainExploreEnabled = props.isMultichainExploreEnabled;
    this._background = props.background;
  }
  draw(target, priceConverter) {
    target.useBitmapCoordinateSpace((scope) => this._drawImpl(scope, priceConverter, this._options?.hoveredXPos));
  }
  update(data, options) {
    this._data = data;
    this._options = options;
  }
  _drawImpl(renderingScope, priceToCoordinate, hoveredXPos) {
    if (this._data === null || this._data.bars.length === 0 || this._data.visibleRange === null || this._options === null) {
      return;
    }
    const ctx = renderingScope.context;
    const bars = this._data.bars.map((bar) => {
      const cumulativePrice = isStackedHistogramData(bar.originalData) ? cumulativeBuildUp(bar.originalData) : [bar.originalData.value];
      return {
        x: bar.x,
        ys: cumulativePrice.map((value) => priceToCoordinate(value) ?? 0)
      };
    });
    calculateColumnPositionsInPlace(
      bars,
      this._data.barSpacing,
      renderingScope.horizontalPixelRatio,
      this._data.visibleRange.from,
      this._data.visibleRange.to
    );
    const zeroY = priceToCoordinate(0) ?? 0;
    for (let i = this._data.visibleRange.from; i < this._data.visibleRange.to; i++) {
      const stack = bars[i];
      const column = stack.column;
      if (!column) {
        return;
      }
      let previousY = zeroY;
      const width = Math.min(
        Math.max(renderingScope.horizontalPixelRatio, column.right - column.left),
        this._data.barSpacing * renderingScope.horizontalPixelRatio
      );
      const margin = width * 0.075;
      const totalBox = positionsBox(zeroY, stack.ys[stack.ys.length - 1], renderingScope.verticalPixelRatio);
      ctx.beginPath();
      const isMultichainExploreEnabled = this._isMultichainExploreEnabled;
      if (this._background) {
        ctx.fillStyle = this._background;
      }
      ctx.roundRect(
        column.left + margin,
        totalBox.position,
        width - margin,
        totalBox.length,
        isMultichainExploreEnabled ? 4 : 8
      );
      ctx.fill();
      ctx.globalCompositeOperation = "source-atop";
      const isStackedHistogram = stack.ys.length > 1;
      const isHovered = hoveredXPos && hoveredXPos >= stack.x - width / 4 && hoveredXPos <= stack.x + width / 4 + 1;
      stack.ys.forEach((y, index) => {
        const color = this._colors[this._colors.length - 1 - index];
        const stackBoxPositions = positionsBox(previousY, y, renderingScope.verticalPixelRatio);
        ctx.fillStyle = color;
        ctx.globalAlpha = isStackedHistogram && !isHovered && isMultichainExploreEnabled ? 0.24 : 1;
        ctx.fillRect(column.left + margin, stackBoxPositions.position, width - margin, stackBoxPositions.length);
        if (isStackedHistogram && isMultichainExploreEnabled && !isHovered) {
          ctx.globalAlpha = 1;
          ctx.fillStyle = color;
          ctx.fillRect(column.left + margin, stackBoxPositions.position, width - margin, 2);
        }
        previousY = y;
      });
      ctx.globalCompositeOperation = "source-over";
    }
  }
}
