import { Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [Shuffle, AnimatedShuffle] = createIcon({
  name: "Shuffle",
  getIcon: (props) => <Svg viewBox="0 0 20 20" fill="none" {...props}><Path
    d="M18.2692 14.4851C18.2267 14.5876 18.1659 14.6792 18.0884 14.7567L16.4225 16.4226C16.26 16.5851 16.0467 16.6667 15.8334 16.6667C15.62 16.6667 15.4067 16.5851 15.2442 16.4226C14.9183 16.0967 14.9183 15.57 15.2442 15.2442L15.4884 15H14.5342C13.5159 15 12.5683 14.5442 11.9317 13.7492L6.76587 7.29251C6.44837 6.89501 5.97417 6.66751 5.46501 6.66751H2.50002C2.03919 6.66751 1.66669 6.29418 1.66669 5.83418C1.66669 5.37418 2.03919 5.00084 2.50002 5.00084H5.46582C6.48415 5.00084 7.43169 5.45666 8.06836 6.25166L13.2342 12.7084C13.5517 13.1059 14.0259 13.3334 14.535 13.3334H15.4892L15.245 13.0892C14.9191 12.7634 14.9191 12.2367 15.245 11.9108C15.5708 11.585 16.0975 11.585 16.4234 11.9108L18.0892 13.5767C18.1667 13.6542 18.2275 13.7458 18.27 13.8483C18.3533 14.0516 18.3533 14.2818 18.2692 14.4851ZM11.6659 8.75003C11.9109 8.75003 12.1525 8.64336 12.3167 8.43753L13.2342 7.2917C13.5517 6.8942 14.0259 6.6667 14.535 6.6667H15.4892L15.245 6.91084C14.9191 7.23667 14.9191 7.76339 15.245 8.08922C15.4075 8.25172 15.6208 8.33336 15.8342 8.33336C16.0475 8.33336 16.2609 8.25172 16.4234 8.08922L18.0892 6.42337C18.1667 6.34587 18.2275 6.25426 18.27 6.15176C18.3541 5.94843 18.3541 5.7183 18.27 5.51496C18.2275 5.41246 18.1667 5.32086 18.0892 5.24336L16.4234 3.5775C16.0975 3.25167 15.5708 3.25167 15.245 3.5775C14.9191 3.90334 14.9191 4.43006 15.245 4.75589L15.4892 5.00003H14.535C13.5167 5.00003 12.5691 5.45584 11.9333 6.25084L11.0167 7.39586C10.7283 7.75503 10.7875 8.28004 11.1459 8.56754C11.3 8.69004 11.4834 8.75003 11.6659 8.75003ZM7.68333 11.5625L6.76587 12.7084C6.44837 13.1059 5.97417 13.3334 5.46501 13.3334H2.50002C2.03919 13.3334 1.66669 13.7067 1.66669 14.1667C1.66669 14.6267 2.03919 15 2.50002 15H5.46582C6.48415 15 7.43171 14.5442 8.06755 13.7492L8.98419 12.6042C9.27253 12.245 9.21333 11.72 8.855 11.4325C8.49333 11.1459 7.97 11.2042 7.68333 11.5625Z"
    fill="currentColor"
  /></Svg>,
  defaultFill: "#9B9B9B"
});
