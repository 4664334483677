"use strict";
import * as Sentry from "@sentry/react";
import noop from "utilities/src/react/noop";
export const sentryEnhancer = Sentry.createReduxEnhancer({
  /**
   * We don't want to store actions as breadcrumbs in Sentry, so we return null to disable the default behavior.
   */
  actionTransformer: noop,
  /**
   * We only want to store a subset of the state in Sentry, containing only the relevant parts for debugging.
   * Note: This function runs on every state update, so we're keeping it as fast as possible by avoiding any function
   * calls and deep object traversals.
   */
  stateTransformer: (state) => {
    const { application, user, localWebTransactions, userSettings } = state;
    return {
      application: {
        fiatOnramp: application.fiatOnramp,
        chainId: application.chainId,
        openModal: application.openModal,
        popupList: application.popupList
      },
      user: {
        lastUpdateVersionTimestamp: user.lastUpdateVersionTimestamp,
        userRouterPreference: user.userRouterPreference,
        userHideClosedPositions: user.userHideClosedPositions,
        userSlippageTolerance: user.userSlippageTolerance,
        userSlippageToleranceHasBeenMigratedToAuto: user.userSlippageToleranceHasBeenMigratedToAuto,
        userDeadline: user.userDeadline,
        timestamp: user.timestamp,
        showSurveyPopup: user.showSurveyPopup
      },
      userSettings: {
        currentLanguage: userSettings.currentLanguage
      },
      localWebTransactions
    };
  }
});
