"use strict";
import { BigNumber } from "@ethersproject/bignumber";
import { useAccount } from "hooks/useAccount";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { TradeFillType } from "state/routing/types";
import { addTransaction, cancelTransaction, removeTransaction } from "state/transactions/reducer";
import {
  TransactionType
} from "state/transactions/types";
import { isConfirmedTx, isPendingTx } from "state/transactions/utils";
import { COMBINED_CHAIN_IDS } from "uniswap/src/types/chains";
export function useTransactionAdder() {
  const account = useAccount();
  const dispatch = useAppDispatch();
  return useCallback(
    (response, info, deadline) => {
      if (account.status !== "connected" || !account.chainId) {
        return;
      }
      const { hash, nonce } = response;
      if (!hash) {
        throw Error("No transaction hash found.");
      }
      const chainId = "chainId" in info && info.chainId || account.chainId;
      dispatch(addTransaction({ hash, from: account.address, info, chainId, nonce, deadline }));
    },
    [account.address, account.chainId, account.status, dispatch]
  );
}
export function useTransactionRemover() {
  const account = useAccount();
  const dispatch = useAppDispatch();
  return useCallback(
    (hash) => {
      if (account.status !== "connected" || !account.chainId) {
        return;
      }
      dispatch(removeTransaction({ hash, chainId: account.chainId }));
    },
    [account.chainId, account.status, dispatch]
  );
}
export function useTransactionCanceller() {
  const dispatch = useAppDispatch();
  return useCallback(
    (hash, chainId, cancelHash) => {
      dispatch(cancelTransaction({ hash, chainId, cancelHash }));
    },
    [dispatch]
  );
}
export function useMultichainTransactions() {
  const state = useAppSelector((state2) => state2.localWebTransactions);
  return COMBINED_CHAIN_IDS.flatMap(
    (chainId) => state[chainId] ? Object.values(state[chainId]).map((tx) => [tx, chainId]) : []
  );
}
function useAllTransactions() {
  const account = useAccount();
  const state = useAppSelector((state2) => state2.localWebTransactions);
  return account.status === "connected" && account.chainId ? state[account.chainId] ?? {} : {};
}
export function useTransaction(transactionHash) {
  const allTransactions = useAllTransactions();
  if (!transactionHash) {
    return void 0;
  }
  return allTransactions[transactionHash];
}
export function useIsTransactionPending(transactionHash) {
  const transactions = useAllTransactions();
  if (!transactionHash || !transactions[transactionHash]) {
    return false;
  }
  return isPendingTx(transactions[transactionHash]);
}
export function useIsTransactionConfirmed(transactionHash) {
  const transactions = useAllTransactions();
  if (!transactionHash || !transactions[transactionHash]) {
    return false;
  }
  return isConfirmedTx(transactions[transactionHash]);
}
export function useSwapTransactionStatus(swapResult) {
  const transaction = useTransaction(swapResult?.type === TradeFillType.Classic ? swapResult.response.hash : void 0);
  if (!transaction) {
    return void 0;
  }
  return transaction.status;
}
function isTransactionRecent(tx) {
  return (/* @__PURE__ */ new Date()).getTime() - tx.addedTime < 864e5;
}
function usePendingApprovalAmount(token, spender) {
  const allTransactions = useAllTransactions();
  return useMemo(() => {
    if (typeof token?.address !== "string" || typeof spender !== "string") {
      return void 0;
    }
    for (const txHash in allTransactions) {
      const tx = allTransactions[txHash];
      if (!tx || isConfirmedTx(tx) || tx.info.type !== TransactionType.APPROVAL) {
        continue;
      }
      if (tx.info.spender === spender && tx.info.tokenAddress === token.address && isTransactionRecent(tx)) {
        return BigNumber.from(tx.info.amount);
      }
    }
    return void 0;
  }, [allTransactions, spender, token?.address]);
}
export function useHasPendingApproval(token, spender) {
  return usePendingApprovalAmount(token, spender)?.gt(0) ?? false;
}
export function useHasPendingRevocation(token, spender) {
  return usePendingApprovalAmount(token, spender)?.eq(0) ?? false;
}
export function usePendingTransactions() {
  const allTransactions = useAllTransactions();
  const account = useAccount();
  return useMemo(
    () => Object.values(allTransactions).filter(
      (tx) => tx.from === account.address && isPendingTx(tx)
    ),
    [account.address, allTransactions]
  );
}
