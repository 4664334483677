"use strict";
import { StyledInput, localeUsesComma } from "components/NumericalInput";
import { NumericalInputFontStyle } from "pages/Swap/common/shared";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { useFormatterLocales } from "utils/formatNumbers";
const inputRegex = RegExp(`^\\d*(\\.\\d{0,2})?$`);
const PercentInput = forwardRef(
  ({ value, onUserInput, placeholder, testId, ...rest }, ref) => {
    const { formatterLocale } = useFormatterLocales();
    const enforcer = (nextUserInput) => {
      const sanitizedInput = nextUserInput.replace(/,/g, ".");
      if (sanitizedInput === "" || inputRegex.test(sanitizedInput)) {
        onUserInput(sanitizedInput);
      }
    };
    const formatValueWithLocale = (value2) => {
      const [searchValue, replaceValue] = localeUsesComma(formatterLocale) ? [/\./g, ","] : [/,/g, "."];
      return value2.toString().replace(searchValue, replaceValue);
    };
    const valueFormattedWithLocale = formatValueWithLocale(value);
    return <StyledInput
      minLength={1}
      maxLength={2}
      {...rest}
      ref={ref}
      value={valueFormattedWithLocale}
      data-testid={testId}
      onChange={(event) => {
        enforcer(event.target.value);
      }}
      inputMode="numeric"
      autoComplete="off"
      autoCorrect="off"
      type="text"
      pattern="^\\d*(\\.\\d{0,2})?$"
      placeholder={placeholder || "0"}
      spellCheck="false"
    />;
  }
);
PercentInput.displayName = "Input";
const MemoizedInput = React.memo(PercentInput);
export const StyledPercentInput = styled(MemoizedInput)`
  max-height: 84px;
  max-width: 100%;
  width: ${({ $width }) => `${$width ?? 43}px`}; // this value is from the size of a 0 which is the default value
  ${NumericalInputFontStyle}

  ::placeholder {
    opacity: 1;
  }
`;
