import { useEffect, useRef, useState } from "react";
import { Popover, Portal } from "tamagui";
import { Flex } from "ui/src/components/layout";
import { MenuContent } from "ui/src/components/menu/MenuContent";
import { useSporeColors } from "ui/src/hooks/useSporeColors";
import { zIndices } from "ui/src/theme";
import { usePrevious } from "utilities/src/react/hooks";
export function ContextMenu({
  children,
  menuOptions,
  menuStyleProps,
  itemId,
  onLeftClick = false,
  closeOnClick = false,
  ...rest
}) {
  const lastItemId = usePrevious(itemId);
  const colors = useSporeColors();
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    if (lastItemId && itemId !== lastItemId) {
      setShowMenu(false);
    }
  }, [itemId, lastItemId]);
  const onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(true);
  };
  const triggerContainerRef = useRef(null);
  const { offset: customOffset, placement } = rest;
  const offset = customOffset || placement && placement !== "bottom-end" ? customOffset : -(triggerContainerRef.current?.offsetHeight ?? 0);
  const contentShadowProps = {
    shadowColor: colors.shadowColor.val,
    shadowRadius: 12,
    shadowOpacity: 0.1
  };
  return <Popover allowFlip offset={offset} open={showMenu} placement="bottom-end" {...rest}>
    {
      /* OVERLAY */
    }
    {
      /* Conditional rendering needs to be used here instead of CSS so that portals aren't duplicated */
    }
    {showMenu && <Portal contain="none" position="unset" onPress={(e) => e.stopPropagation()}><Flex
      height="100vh"
      left={0}
      opacity={1}
      pointerEvents="auto"
      style={{ position: "fixed" }}
      top={0}
      width="100vh"
      zIndex={zIndices.modalBackdrop}
      onPress={() => setShowMenu(false)}
    /></Portal>}
    {
      /* TRIGGER/BUTTON */
    }
    <Popover.Trigger><div
      ref={triggerContainerRef}
      onClick={onLeftClick ? onContextMenu : void 0}
      onContextMenu={onLeftClick ? void 0 : onContextMenu}
    >{children}</div></Popover.Trigger>
    {
      /* CONTENT */
    }
    <Popover.Content
      animation={[
        "quick",
        {
          opacity: {
            overshootClamping: true
          }
        }
      ]}
      borderColor="$surface3"
      borderRadius="$rounded16"
      borderWidth="$spacing1"
      disableRemoveScroll={false}
      enterStyle={{ y: -10, opacity: 0 }}
      exitStyle={{ y: -10, opacity: 0 }}
      p="$none"
      {...contentShadowProps}
    >
      <div><MenuContent
        items={menuOptions}
        onClose={closeOnClick ? () => setShowMenu(false) : void 0}
        {...menuStyleProps}
      /></div>
      <Popover.Arrow backgroundColor="transparent" />
    </Popover.Content>
  </Popover>;
}
