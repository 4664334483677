"use strict";
import { MoonpayEventName } from "@uniswap/analytics-events";
import { DEFAULT_TXN_DISMISS_MS } from "constants/misc";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ApplicationModal,
  addPopup,
  addSuppressedPopups,
  removePopup,
  removeSuppressedPopups,
  setCloseModal,
  setFiatOnrampAvailability,
  setOpenModal
} from "state/application/reducer";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { logger } from "utilities/src/logger/logger";
export function useModalIsOpen(modal) {
  const openModal = useAppSelector((state) => state.application.openModal?.name);
  return openModal === modal;
}
async function getMoonpayAvailability() {
  const moonpayPublishableKey = process.env.REACT_APP_MOONPAY_PUBLISHABLE_KEY;
  if (!moonpayPublishableKey) {
    throw new Error("Must provide a publishable key for moonpay.");
  }
  const moonpayApiURI = process.env.REACT_APP_MOONPAY_API;
  if (!moonpayApiURI) {
    throw new Error("Must provide an api endpoint for moonpay.");
  }
  const res = await fetch(`${moonpayApiURI}/v4/ip_address?apiKey=${moonpayPublishableKey}`);
  const data = await res.json();
  return data.isBuyAllowed ?? false;
}
export function useFiatOnrampAvailability(shouldCheck, callback) {
  const dispatch = useAppDispatch();
  const { available, availabilityChecked } = useAppSelector((state) => state.application.fiatOnramp);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function checkAvailability() {
      setError(null);
      setLoading(true);
      try {
        const result = await getMoonpayAvailability();
        sendAnalyticsEvent(MoonpayEventName.MOONPAY_GEOCHECK_COMPLETED, { success: result, networkError: false });
        if (stale) {
          return;
        }
        dispatch(setFiatOnrampAvailability(result));
        if (result && callback) {
          callback();
        }
      } catch (e) {
        logger.warn("useFiatOnrampAvailability", "checkAvailability", "Error fetching FOR availability", e);
        if (stale) {
          return;
        }
        setError("Error, try again later.");
        dispatch(setFiatOnrampAvailability(false));
      } finally {
        if (!stale) {
          setLoading(false);
        }
      }
    }
    if (!availabilityChecked && shouldCheck) {
      checkAvailability();
    }
    let stale = false;
    return () => {
      stale = true;
    };
  }, [availabilityChecked, callback, dispatch, shouldCheck]);
  return { available, availabilityChecked, loading, error };
}
export function useToggleModal(modal) {
  const isOpen = useModalIsOpen(modal);
  const dispatch = useAppDispatch();
  return useCallback(() => {
    if (isOpen) {
      dispatch(setCloseModal(modal));
    } else {
      dispatch(setOpenModal({ name: modal }));
    }
  }, [dispatch, modal, isOpen]);
}
export function useCloseModal(modal) {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(setCloseModal(modal)), [dispatch, modal]);
}
export function useOpenModal(modal) {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal]);
}
export function useToggleSettingsMenu() {
  return useToggleModal(ApplicationModal.SETTINGS);
}
export function useShowClaimPopup() {
  return useModalIsOpen(ApplicationModal.CLAIM_POPUP);
}
export function useToggleShowClaimPopup() {
  return useToggleModal(ApplicationModal.CLAIM_POPUP);
}
export function useToggleSelfClaimModal() {
  return useToggleModal(ApplicationModal.SELF_CLAIM);
}
export function useTogglePrivacyPolicy() {
  return useToggleModal(ApplicationModal.PRIVACY_POLICY);
}
export function useAddPopup() {
  const dispatch = useAppDispatch();
  return useCallback(
    (content, key, removeAfterMs) => {
      dispatch(addPopup({ content, key, removeAfterMs: removeAfterMs ?? DEFAULT_TXN_DISMISS_MS }));
    },
    [dispatch]
  );
}
export function useRemovePopup() {
  const dispatch = useAppDispatch();
  return useCallback(
    (key) => {
      dispatch(removePopup({ key }));
    },
    [dispatch]
  );
}
export function useActivePopups() {
  const list = useAppSelector((state) => state.application.popupList);
  return useMemo(() => list.filter((item) => item.show), [list]);
}
export function useSuppressPopups(popupTypes) {
  const dispatch = useAppDispatch();
  const suppressPopups = useCallback(() => dispatch(addSuppressedPopups({ popupTypes })), [dispatch, popupTypes]);
  const unsuppressPopups = useCallback(() => dispatch(removeSuppressedPopups({ popupTypes })), [dispatch, popupTypes]);
  return {
    suppressPopups,
    unsuppressPopups
  };
}
