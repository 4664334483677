"use strict";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import { useCurrency } from "hooks/Tokens";
import { useAccount } from "hooks/useAccount";
import useENSAddress from "hooks/useENSAddress";
import useENSName from "hooks/useENSName";
import { GasSpeed, useTransactionGasFee } from "hooks/useTransactionGasFee";
import { useUSDTokenUpdater } from "hooks/useUSDTokenUpdater";
import { useCurrencyBalances } from "lib/hooks/useCurrencyBalance";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useMemo } from "react";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
import { nativeOnChain } from "uniswap/src/constants/tokens";
import { useUnitagByAddress, useUnitagByName } from "uniswap/src/features/unitags/hooks";
import { isAddress } from "utilities/src/addresses";
import { useCreateTransferTransaction } from "utils/transfer";
export var SendInputError = /* @__PURE__ */ ((SendInputError2) => {
  SendInputError2["INSUFFICIENT_FUNDS"] = "INSUFFICIENT_FUNDS";
  SendInputError2["INSUFFICIENT_FUNDS_FOR_GAS"] = "INSUFFICIENT_FUNDS_FOR_GAS";
  return SendInputError2;
})(SendInputError || {});
export function useDerivedSendInfo(state) {
  const account = useAccount();
  const { provider } = useWeb3React();
  const { chainId } = useSwapAndLimitContext();
  const { exactAmountToken, exactAmountFiat, inputInFiat, inputCurrency, recipient, validatedRecipientData } = state;
  const { unitag: recipientInputUnitag } = useUnitagByName(validatedRecipientData ? void 0 : recipient);
  const recipientInputUnitagUsername = validatedRecipientData?.unitag ?? recipientInputUnitag?.username;
  const recipientInputUnitagAddress = recipientInputUnitag?.address?.address;
  const { address: recipientInputEnsAddress } = useENSAddress(validatedRecipientData ? void 0 : recipient);
  const validatedRecipientAddress = useMemo(() => {
    if (validatedRecipientData) {
      return validatedRecipientData.address;
    }
    return isAddress(recipient) || isAddress(recipientInputEnsAddress) || isAddress(recipientInputUnitagAddress) || void 0;
  }, [recipient, recipientInputEnsAddress, recipientInputUnitagAddress, validatedRecipientData]);
  const { unitag } = useUnitagByAddress(recipientInputUnitagUsername ? void 0 : validatedRecipientAddress);
  const { ENSName } = useENSName(validatedRecipientData?.ensName ? void 0 : validatedRecipientAddress);
  const recipientData = useMemo(() => {
    if (validatedRecipientAddress) {
      return {
        address: validatedRecipientAddress,
        ensName: recipientInputEnsAddress ? recipient : validatedRecipientData?.ensName ?? ENSName ?? void 0,
        unitag: recipientInputUnitagUsername ?? unitag?.username
      };
    }
    return void 0;
  }, [
    validatedRecipientAddress,
    recipientInputEnsAddress,
    recipient,
    validatedRecipientData?.ensName,
    ENSName,
    recipientInputUnitagUsername,
    unitag?.username
  ]);
  const nativeCurrency = useCurrency(NATIVE_CHAIN_ID, chainId);
  const [inputCurrencyBalance, nativeCurrencyBalance] = useCurrencyBalances(
    account.address,
    useMemo(() => [inputCurrency, nativeCurrency], [inputCurrency, nativeCurrency])
  );
  const { formattedAmount: exactAmountOut } = useUSDTokenUpdater(
    inputInFiat,
    exactAmountToken ?? exactAmountFiat,
    inputCurrency
  );
  const parsedTokenAmount = useMemo(() => {
    return tryParseCurrencyAmount(inputInFiat ? exactAmountOut : exactAmountToken, inputCurrency);
  }, [exactAmountOut, exactAmountToken, inputCurrency, inputInFiat]);
  const transferInfo = useMemo(() => {
    return {
      provider,
      account: account.address,
      chainId,
      currencyAmount: parsedTokenAmount,
      toAddress: recipientData?.address
    };
  }, [account.address, chainId, parsedTokenAmount, provider, recipientData?.address]);
  const transferTransaction = useCreateTransferTransaction(transferInfo);
  const gasFee = useTransactionGasFee(transferTransaction, GasSpeed.Normal, !transferTransaction);
  const gasFeeCurrencyAmount = useMemo(() => {
    if (!chainId || !gasFee?.value) {
      return void 0;
    }
    return CurrencyAmount.fromRawAmount(nativeOnChain(chainId), gasFee.value);
  }, [chainId, gasFee?.value]);
  const inputError = useMemo(() => {
    const insufficientBalance = parsedTokenAmount && inputCurrencyBalance?.lessThan(parsedTokenAmount);
    if (insufficientBalance) {
      return "INSUFFICIENT_FUNDS" /* INSUFFICIENT_FUNDS */;
    }
    if (!gasFee.value || !nativeCurrency || !nativeCurrencyBalance) {
      return void 0;
    }
    let totalAmount = CurrencyAmount.fromRawAmount(nativeCurrency, gasFee?.value);
    if (parsedTokenAmount && inputCurrency && nativeCurrency?.equals(inputCurrency)) {
      totalAmount = totalAmount?.add(parsedTokenAmount);
    }
    if (!totalAmount || nativeCurrencyBalance?.lessThan(totalAmount)) {
      return "INSUFFICIENT_FUNDS_FOR_GAS" /* INSUFFICIENT_FUNDS_FOR_GAS */;
    }
    return void 0;
  }, [gasFee.value, inputCurrency, inputCurrencyBalance, nativeCurrencyBalance, nativeCurrency, parsedTokenAmount]);
  return useMemo(
    () => ({
      currencyBalance: inputCurrencyBalance,
      exactAmountOut,
      parsedTokenAmount,
      recipientData,
      transaction: transferTransaction,
      gasFeeCurrencyAmount,
      gasFee,
      inputError
    }),
    [
      exactAmountOut,
      gasFeeCurrencyAmount,
      gasFee,
      inputCurrencyBalance,
      inputError,
      parsedTokenAmount,
      recipientData,
      transferTransaction
    ]
  );
}
