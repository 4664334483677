"use strict";
import { useMemo } from "react";
import { nativeOnChain } from "uniswap/src/constants/tokens";
import { UniverseChainId } from "uniswap/src/types/chains";
export default function useNativeCurrency(chainId) {
  return useMemo(
    () => chainId ? nativeOnChain(chainId) : (
      // display mainnet when not connected
      nativeOnChain(UniverseChainId.Mainnet)
    ),
    [chainId]
  );
}
