"use strict";
import useParsedQueryString from "hooks/useParsedQueryString";
import { useMemo } from "react";
import store from "state";
import {
  DEFAULT_LOCALE,
  WEB_SUPPORTED_LANGUAGES,
  mapLocaleToLanguage
} from "uniswap/src/features/language/constants";
import { getLocale, useCurrentLocale } from "uniswap/src/features/language/hooks";
export function parseLocale(maybeSupportedLocale) {
  if (typeof maybeSupportedLocale !== "string") {
    return void 0;
  }
  const lowerMaybeSupportedLocale = maybeSupportedLocale.toLowerCase();
  return WEB_SUPPORTED_LANGUAGES.map((lang) => getLocale(lang)).find(
    (locale) => locale.toLowerCase() === lowerMaybeSupportedLocale || locale.split("-")[0] === lowerMaybeSupportedLocale
  );
}
export function navigatorLocale() {
  if (!navigator.language) {
    return void 0;
  }
  const [language, region] = navigator.language.split("-");
  if (region) {
    return parseLocale(`${language}-${region.toUpperCase()}`) ?? parseLocale(language);
  }
  return parseLocale(language);
}
export function storeLocale() {
  const storeLanguage = store.getState().userSettings.currentLanguage;
  return getLocale(storeLanguage);
}
function useUrlLocale() {
  const parsed = useParsedQueryString();
  return parseLocale(parsed.lng);
}
export function useActiveLocale() {
  const urlLocale = useUrlLocale();
  const userLocale = useCurrentLocale();
  return useMemo(() => urlLocale ?? userLocale ?? navigatorLocale() ?? DEFAULT_LOCALE, [urlLocale, userLocale]);
}
export function useActiveLanguage() {
  const locale = useActiveLocale();
  return mapLocaleToLanguage[locale];
}
