"use strict";
import { PopupType, addPopup } from "state/application/reducer";
import {
  handleApprovalTransactionStep,
  handleOnChainStep,
  handleSignatureStep
} from "state/sagas/transactions/utils";
import {
  TransactionType
} from "state/transactions/types";
import invariant from "tiny-invariant";
import { call, put } from "typed-redux-saga";
import {
  TransactionStepType,
  generateTransactionSteps
} from "uniswap/src/features/transactions/swap/utils/generateTransactionSteps";
import { createSaga } from "uniswap/src/utils/saga";
import { logger } from "utilities/src/logger/logger";
import { currencyId } from "utils/currencyId";
function* getLiquidityTxRequest(step, signature) {
  if (step.type === TransactionStepType.IncreasePositionTransaction || step.type === TransactionStepType.DecreasePositionTransaction) {
    return step.txRequest;
  }
  if (!signature) {
    throw new Error("Signature required for async increase position transaction step");
  }
  try {
    const txRequest = yield* call(step.getTxRequest, signature);
    invariant(txRequest !== void 0);
    return txRequest;
  } catch {
    throw new Error("Failed to get transaction request");
  }
}
function* handlePositionTransactionStep(params) {
  const { action, step, signature } = params;
  const info = getLiquidityTransactionInfo(action, step.type);
  const txRequest = yield* call(getLiquidityTxRequest, step, signature);
  const onChainStep = { ...step, txRequest };
  const hash = yield* call(handleOnChainStep, { ...params, info, step: onChainStep, shouldWaitForConfirmation: false });
  yield* put(addPopup({ content: { type: PopupType.Transaction, hash }, key: hash }));
}
function* modifyLiquidity(params) {
  const {
    account,
    setCurrentStep,
    steps,
    liquidityTxContext: { action },
    onSuccess,
    onFailure
  } = params;
  let signature;
  try {
    for (const step of steps) {
      switch (step.type) {
        case TransactionStepType.TokenApprovalTransaction: {
          yield* call(handleApprovalTransactionStep, { account, step, setCurrentStep });
          break;
        }
        case TransactionStepType.Permit2Signature: {
          signature = yield* call(handleSignatureStep, { account, step, setCurrentStep });
          break;
        }
        case TransactionStepType.IncreasePositionTransaction:
        case TransactionStepType.IncreasePositionTransactionAsync:
        case TransactionStepType.DecreasePositionTransaction:
          yield* call(handlePositionTransactionStep, { account, step, setCurrentStep, action, signature });
          break;
        default: {
          throw new Error("Unexpected step type");
        }
      }
    }
  } catch (e) {
    logger.error(e, { tags: { file: "liquiditySaga", function: "modifyLiquidity" } });
    onFailure();
    return;
  }
  yield* call(onSuccess);
}
function* liquidity(params) {
  const { liquidityTxContext, startChainId, selectChain, onFailure } = params;
  const steps = yield* call(generateTransactionSteps, liquidityTxContext);
  params.setSteps(steps);
  const token0ChainId = liquidityTxContext.action.currency0Amount.currency.chainId;
  const token1ChainId = liquidityTxContext.action.currency1Amount.currency.chainId;
  if (token0ChainId !== token1ChainId && token0ChainId !== startChainId) {
    const chainSwitched = yield* call(selectChain, token0ChainId);
    if (!chainSwitched) {
      onFailure();
      return void 0;
    }
  }
  return yield* modifyLiquidity({
    ...params,
    steps
  });
}
export const liquiditySaga = createSaga(liquidity, "liquiditySaga");
function getLiquidityTransactionInfo(action, type) {
  const {
    currency0Amount: { currency: currency0, quotient: quotient0 },
    currency1Amount: { currency: currency1, quotient: quotient1 }
  } = action;
  return {
    type: type === TransactionStepType.DecreasePositionTransaction ? TransactionType.DECREASE_LIQUIDITY : TransactionType.INCREASE_LIQUIDITY,
    token0CurrencyId: currencyId(currency0),
    token1CurrencyId: currencyId(currency1),
    token0CurrencyAmountRaw: quotient0.toString(),
    token1CurrencyAmountRaw: quotient1.toString()
  };
}
